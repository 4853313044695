<template>
  <template v-if="!loading">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="10" width-xs="12">
        <vl-title tag-name="h2"> {{ $t('producttransformation.detail.title') }} </vl-title>
      </vl-column>
      <vl-column width="2" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button
            icon="plus"
            mod-icon-before
            :mod-disabled="editStore.isCreate(ProductProcessDefinitionEditType.ProductTransformation)"
            @click="editStore.openCreate(ProductProcessDefinitionEditType.ProductTransformation)"
            >{{ $t('general.add') }}</vl-button
          >
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid :class="{ edit: editStore.isCreate(ProductProcessDefinitionEditType.ProductTransformation) }">
      <vl-column>
        <ProductTransformationCreate
          v-if="editStore.isCreate(ProductProcessDefinitionEditType.ProductTransformation)"
          :definition-id="definitionId"
        />
      </vl-column>
    </vl-grid>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <vl-accordion-list v-if="items && items.length">
          <vl-accordion-list-item v-for="(transformation, i) in items" :id="i" :key="i">
            <vl-accordion :id="i.toString()" :title="getLabel(transformation)">
              <vl-grid
                :class="{
                  edit: editStore.isEdit(ProductProcessDefinitionEditType.ProductTransformation, transformation.id),
                }"
              >
                <vl-column width="8">
                  <ProductTransformationEdit
                    v-if="editStore.isEdit(ProductProcessDefinitionEditType.ProductTransformation, transformation.id)"
                    ref="editForm"
                    :definition-id="definitionId"
                    :detail="transformation"
                  />
                  <ProductTransformationRead v-else :detail="transformation" />
                </vl-column>
                <vl-column width="4">
                  <vl-action-group
                    v-if="
                      transformation &&
                      !editStore.isEdit(ProductProcessDefinitionEditType.ProductTransformation, transformation.id)
                    "
                    mod-align-right
                    mod-space
                    between
                  >
                    <vl-button
                      icon="pencil"
                      mod-icon-before
                      mod-secondary
                      :mod-disabled="editStore.editing"
                      @click="
                        editStore.openEdit(ProductProcessDefinitionEditType.ProductTransformation, transformation.id)
                      "
                      >{{ $t('general.edit') }}</vl-button
                    >
                    <vl-button
                      icon="trash"
                      mod-icon-before
                      mod-error
                      @click="
                        editStore.openDelete(ProductProcessDefinitionEditType.ProductTransformation, transformation.id)
                      "
                      >{{ $t('general.delete') }}</vl-button
                    >
                  </vl-action-group>
                  <vl-action-group v-else mod-align-right mod-space between>
                    <vl-button icon="save" x mod-icon-before :mod-loading="reloading" @click="save">{{
                      $t('general.save')
                    }}</vl-button>
                    <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeEdit">
                      {{ $t('general.cancel') }}
                    </vl-button>
                  </vl-action-group>
                </vl-column>
              </vl-grid>
            </vl-accordion>
          </vl-accordion-list-item>
        </vl-accordion-list>
        <template v-else>
          <vl-annotation>{{ $t('producttransformation.detail.empty') }}</vl-annotation>
        </template>
      </vl-column>
    </vl-grid>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('producttransformation.detail.delete.title')"
      :open="editStore.isDelete(ProductProcessDefinitionEditType.ProductTransformation, deleteKey)"
    >
      <vl-action-group>
        <vl-button icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </template>
</template>
<script lang="ts" setup>
import ProductTransformationRead from './ProductTransformationRead.vue';
import ProductTransformationCreate from './ProductTransformationCreate.vue';
import ProductTransformationEdit from './ProductTransformationEdit.vue';
import { ProductTransformationDetailDto } from '@/api/portal-api/clients';
import { useProductTransformationStore } from '@/modules/configuration/store/product-transformation/product-transformation.store';
import {
  ProductProcessDefinitionEditType,
  useProductProcessDefinitionEditStore,
} from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import { computed, onMounted, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const store = useProductTransformationStore();
const editStore = useProductProcessDefinitionEditStore();

const editForm: Ref<typeof ProductTransformationEdit | (typeof ProductTransformationEdit)[]> = ref(null);

const route = useRoute();

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): ProductTransformationDetailDto[] => {
  return store.byDefinition(definitionId.value);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const reloading = computed((): boolean => {
  return store.reloading;
});

onMounted(async () => {
  await store.getByDefinition(definitionId.value);
});

const save = () => {
  if (Array.isArray(editForm?.value)) {
    editForm.value[0].onSubmit();
  } else {
    editForm.value.onSubmit();
  }
};

const remove = async (transformationId: number) => {
  await store.remove(definitionId.value, transformationId);
  editStore.closeDelete();
};

const getLabel = (item: ProductTransformationDetailDto): string => {
  const unknown = '(?)';
  const from = `${item.fromEntity?.code ?? unknown}`;
  const to = `${item.toEntity?.code ?? unknown}`;
  const type = item.transformationType?.name ?? '/';

  if (from == unknown && to == unknown) {
    return type;
  }

  return t('producttransformation.detail.linkTo', { from, to, type });
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
</style>
