<template>
  <vl-layout>
    <PbsGoBackLink :label="$t('general.backToResults')" link="/configuration#organisation" />
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1"> {{ $t('organisations.create.title') }} </vl-title>
      </vl-column>
    </vl-grid>
    <form @submit="onSubmit">
      <vl-grid>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="name"
            :label="t('organisations.create.name')"
            :placeholder="t('organisations.create.name')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="ovoNumber"
            :label="t('organisations.create.ovoNumber')"
            :placeholder="t('organisations.create.ovoNumber')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="kboNumber"
            :label="t('organisations.create.kboNumber')"
            :placeholder="t('organisations.create.kboNumber')"
          />
        </vl-form-column>
        <vl-action-group v-vl-spacer:bottom.small>
          <vl-button icon="save" :mod-loading="loading" mod-icon-before type="submit"
            >{{ $t('general.save') }}
          </vl-button>
        </vl-action-group>
      </vl-grid>
    </form>
  </vl-layout>
</template>

<script lang="ts" setup>
import { useOrganisationDetailStore } from '@/modules/configuration/store/organisation/organisation-detail.store';
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, string, mixed } from 'yup';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import { useRouter } from 'vue-router';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';

const { t } = useI18n();
const store = useOrganisationDetailStore();
const router = useRouter();

const validationSchema = object({
  name: string()
    .required(t('validations.messages.required', { _field_: t('organisations.detail.name') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('organisations.detail.name'), length: 256 })),
  kboNumber: string()
    .nullable()
    .max(10, t('validations.messages.maxChar', { _field_: t('organisations.detail.kboNumber'), length: 10 })),
  ovoNumber: string()
    .nullable()
    .max(25, t('validations.messages.maxChar', { _field_: t('organisations.detail.ovoNumber'), length: 25 })),
  custom: mixed().test(
    'custom-validation',
    'The properties do not meet the validation criteria',
    function (_, context) {
      const { kboNumber, ovoNumber } = context.parent;
      if (!kboNumber?.trim() && !ovoNumber?.trim()) {
        return context.createError({
          path: 'kboNumber',
          message: t('organisations.detail.kboOrOvoCodeRequired'),
        });
      }
      return true;
    },
  ),
});

const { handleSubmit } = useForm({
  validationSchema,
});

const loading = computed((): boolean => {
  return store.loading;
});

const onSuccess = async (values) => {
  const id = await store.create({
    name: values.name?.trim(),
    kboNumber: values.kboNumber?.trim(),
    ovoNumber: values.ovoNumber?.trim(),
  });

  if (id) {
    router.push({
      name: 'organisation.detail',
      params: {
        id,
      },
    });
  }
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
