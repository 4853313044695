<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('productprocessdefinition.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('productprocessdefinition.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('productprocessdefinition.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ detail.description }}</vl-properties-data>
    <vl-properties-label>{{ $t('productprocessdefinition.detail.organisation') }}</vl-properties-label>
    <vl-properties-data>{{ detail.organisation }} ({{ detail.ovoNumber }})</vl-properties-data>
    <vl-properties-label>{{ $t('productprocessdefinition.detail.deliveryprocessdefinition') }}</vl-properties-label>
    <vl-properties-data v-if="detail.deliveryProcessDefinition">
      <PbsEntityRouterLink type="deliveryProcessDefinition" :detail="detail.deliveryProcessDefinition" />
    </vl-properties-data>
    <vl-properties-data v-else>/</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { ProductProcessDefinitionDetailDto } from '@/api/portal-api/clients';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import { computed } from 'vue';
import PbsEntityRouterLink from '@/components/PbsEntityRouterLink.vue';

const store = useProductProcessDefinitionDetailStore();

const detail = computed((): ProductProcessDefinitionDetailDto => {
  return store.detail;
});
</script>

<style lang="scss" scoped>
:deep(.vl-properties__label) {
  max-width: 20rem !important;
}
</style>
