<template>
  <template v-if="!loading">
    <vl-grid v-vl-spacer:bottom.medium class="section">
      <vl-column width="10" width-xs="12">
        <vl-title tag-name="h3"> {{ $t('sourceentity.detail.title') }} </vl-title>
      </vl-column>
      <vl-column width="2" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button icon="plus" mod-icon-before :mod-disabled="isCreating" @click="onCreateButtonClick">{{
            $t('general.add')
          }}</vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid :class="{ edit: isCreating }">
      <vl-column>
        <SourceEntityCreate v-if="isCreating" :specification-id="specificationId" />
      </vl-column>
    </vl-grid>
    <vl-grid v-if="items && items.length" v-vl-spacer:bottom.medium class="section">
      <vl-column width="12" width-xs="12">
        <vl-accordion-list>
          <vl-accordion-list-item v-for="(entity, i) in items" :id="i" :key="i">
            <vl-accordion :id="i.toString()" :title="getLabel(entity)">
              <vl-grid :class="{ edit: isEditing(entity.id) }">
                <vl-column width="8">
                  <SourceEntityEdit
                    v-if="isEditing(entity.id)"
                    ref="editForm"
                    :detail="entity"
                    :specification-id="specificationId"
                  />
                  <SourceEntityRead v-else :detail="entity" />
                </vl-column>
                <vl-column width="4">
                  <vl-action-group v-if="!isEditing(entity.id)" mod-align-right mod-space between>
                    <vl-button
                      icon="pencil"
                      mod-icon-before
                      mod-secondary
                      :mod-disabled="editStore.editing"
                      @click="onEditButtonClick(entity.id)"
                    >
                      {{ $t('general.edit') }}
                    </vl-button>
                    <vl-button
                      v-if="!hasChildren(entity.id) && !hasTransformation(entity.id)"
                      icon="trash"
                      mod-icon-before
                      mod-error
                      @click="onDeleteButtonClick(entity.id)"
                      >{{ $t('general.delete') }}</vl-button
                    >
                  </vl-action-group>
                  <vl-action-group v-else mod-align-right mod-space between>
                    <vl-button icon="save" x mod-icon-before @click="onSaveButtonClick">{{
                      $t('general.save')
                    }}</vl-button>
                    <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="onCancelSaveButtonClick">
                      {{ $t('general.cancel') }}
                    </vl-button>
                  </vl-action-group>
                </vl-column>
              </vl-grid>
              <ProductTransformationLookupLink :source-entity="entity" />
            </vl-accordion>
          </vl-accordion-list-item>
        </vl-accordion-list>
      </vl-column>
    </vl-grid>
    <vl-grid v-else v-vl-spacer:bottom.medium class="section">
      <vl-column>
        <vl-annotation>{{ $t('sourceentity.detail.empty') }}</vl-annotation>
      </vl-column>
    </vl-grid>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('sourceentity.detail.delete.title')"
      :open="editStore.isDelete(ProductProcessDefinitionEditType.SourceEntity, deleteKey)"
    >
      <vl-action-group>
        <vl-button icon="trash" mod-icon-before mod-error @click="onConfirmRemoveButtonClick(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="onCancelRemoveButtonClick">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </template>
</template>
<script lang="ts" setup>
import SourceEntityRead from './SourceEntityRead.vue';
import SourceEntityCreate from './SourceEntityCreate.vue';
import SourceEntityEdit from './SourceEntityEdit.vue';
import ProductTransformationLookupLink from '../product-transformation-lookup-link/ProductTransformationLookupLink.vue';
import { SourceEntityDetailDto } from '@/api/portal-api/clients';
import { useSourceEntityStore } from '@/modules/configuration/store/source-entity/source-entity.store';
import { computed, onMounted, Ref, ref } from 'vue';
import {
  ProductProcessDefinitionEditType,
  useProductProcessDefinitionEditStore,
} from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import { useProductTransformationLookupLinkStore } from '@/modules/configuration/store/product-transformation-lookup-link/product-transformation-lookup-link.store';
import { useProductTransformationStore } from '@/modules/configuration/store/product-transformation/product-transformation.store';
import { useRoute } from 'vue-router';

const store = useSourceEntityStore();
const transformationStore = useProductTransformationStore();
const editStore = useProductProcessDefinitionEditStore();
const lookupLinkStore = useProductTransformationLookupLinkStore();

const editForm: Ref<typeof SourceEntityEdit | (typeof SourceEntityEdit)[]> = ref(null);
const route = useRoute();

const props = defineProps({
  specificationId: {
    type: Number,
    required: true,
  },
});

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): SourceEntityDetailDto[] => {
  return store.bySpecification(props.specificationId);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

const isCreating = computed((): boolean => {
  return editStore.isCreate(ProductProcessDefinitionEditType.SourceEntity);
});

const isEditing = (entityId: number): boolean => {
  return editStore.isEdit(ProductProcessDefinitionEditType.SourceEntity, entityId);
};

const hasChildren = (entityId: number): boolean => {
  const links = lookupLinkStore.bySourceEntity(entityId);

  return links.length > 0;
};

const hasTransformation = (id: number): boolean => {
  return transformationStore.byDefinition(parseInt(route.params.id as string)).some((x) => x.fromEntity?.id === id);
};

onMounted(async () => {
  if (props.specificationId) {
    await store.getBySpecification(props.specificationId);
  }
});

const onCreateButtonClick = () => {
  editStore.openCreate(ProductProcessDefinitionEditType.SourceEntity);
};

const onEditButtonClick = (entityId: number) => {
  editStore.openEdit(ProductProcessDefinitionEditType.SourceEntity, entityId);
};

const onDeleteButtonClick = (entityId: number) => {
  editStore.openDelete(ProductProcessDefinitionEditType.SourceEntity, entityId);
};

const onSaveButtonClick = () => {
  if (Array.isArray(editForm?.value)) {
    editForm.value[0].onSubmit();
  } else {
    editForm.value.onSubmit();
  }
};

const onCancelSaveButtonClick = () => {
  editStore.closeEdit();
};

const onConfirmRemoveButtonClick = async (entityId: number) => {
  await store.remove(props.specificationId, entityId);
  editStore.closeDelete();
};

const onCancelRemoveButtonClick = () => {
  editStore.closeDelete();
};

const getLabel = (item: SourceEntityDetailDto): string => {
  return `${item.code} - ${item.label}`;
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
.vl-grid {
  flex-grow: 1;
}
.section {
  padding-left: 20px;
}
</style>
