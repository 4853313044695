<template>
  <div>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="8" width-xs="12">
        <vl-title tag-name="h2"> {{ $t('entityAttribute.detail.title') }} </vl-title>
      </vl-column>
      <vl-column width="4" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button
            icon="plus"
            mod-icon-before
            :mod-disabled="editStore.isCreate(EntityModelEditType.EntityAttribute)"
            @click="editStore.openCreate(EntityModelEditType.EntityAttribute)"
            >{{ $t('general.add') }}</vl-button
          >
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid :class="{ edit: editStore.isCreate(EntityModelEditType.EntityAttribute) }">
      <vl-column>
        <EntityAttributeCreate v-if="editStore.isCreate(EntityModelEditType.EntityAttribute)" :model="model" />
      </vl-column>
    </vl-grid>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <vl-accordion-list v-if="items && items.length">
          <vl-accordion-list-item v-for="(entityAttribute, i) in items" :id="i" :key="i">
            <vl-accordion :id="i.toString()" :title="getLabel(entityAttribute)">
              <vl-grid :class="{ edit: editStore.isEdit(EntityModelEditType.EntityAttribute, entityAttribute.id) }">
                <vl-column width="8">
                  <EntityAttributeRead
                    v-if="!editStore.isEdit(EntityModelEditType.EntityAttribute, entityAttribute.id)"
                    :detail="entityAttribute"
                  />
                  <EntityAttributeEdit v-else ref="editForm" :detail="entityAttribute" :model="model" />
                </vl-column>
                <vl-column width="4">
                  <vl-action-group
                    v-if="!editStore.isEdit(EntityModelEditType.EntityAttribute, entityAttribute.id)"
                    mod-align-right
                    mod-space
                    between
                  >
                    <vl-button
                      icon="pencil"
                      mod-icon-before
                      mod-secondary
                      :mod-disabled="editStore.editing"
                      @click="editStore.openEdit(EntityModelEditType.EntityAttribute, entityAttribute.id)"
                      >{{ $t('general.edit') }}</vl-button
                    >
                    <vl-button
                      icon="trash"
                      mod-icon-before
                      mod-error
                      @click="editStore.openDelete(EntityModelEditType.EntityAttribute, entityAttribute.id)"
                      >{{ $t('general.delete') }}</vl-button
                    >
                  </vl-action-group>
                  <vl-action-group v-else mod-align-right mod-space between>
                    <vl-button icon="save" x mod-icon-before :mod-loading="reloading" @click="save">{{
                      $t('general.save')
                    }}</vl-button>
                    <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeEdit">
                      {{ $t('general.cancel') }}
                    </vl-button>
                  </vl-action-group>
                </vl-column>
              </vl-grid></vl-accordion
            >
          </vl-accordion-list-item>
        </vl-accordion-list>
        <template v-else>
          <vl-annotation>{{ $t('entityAttribute.detail.empty') }}</vl-annotation>
        </template>
      </vl-column>
    </vl-grid>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('entityAttribute.detail.delete.title')"
      :open="editStore.isDelete(EntityModelEditType.EntityAttribute, deleteKey)"
    >
      <vl-action-group>
        <vl-button icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </div>
</template>
<script lang="ts" setup>
import { EntityAttributeDetailDto } from '@/api/portal-api/clients';
import { useEntityAttributeStore } from '@/modules/configuration/store/entity-attribute/entity-attribute.store';
import { Ref, computed, ref } from 'vue';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import {
  useEntityModelEditStore,
  EntityModelEditType,
} from '@/modules/configuration/store/entity-model/entity-model-edit.store';
import { EntityModelDetailDto } from '@/api/portal-api/clients';
import EntityAttributeRead from './EntityAttributeRead.vue';
import EntityAttributeEdit from './EntityAttributeEdit.vue';
import EntityAttributeCreate from './EntityAttributeCreate.vue';

const store = useEntityAttributeStore();
const editStore = useEntityModelEditStore();
const route = useRoute();

const editForm: Ref<(typeof EntityAttributeEdit)[]> = ref(null);

const { t } = useI18n();

defineProps<{
  model: EntityModelDetailDto;
}>();

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): EntityAttributeDetailDto[] => {
  return store.byModel(id.value);
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

onMounted(async () => {
  await store.getByModel(id.value);
});

const save = () => {
  if (Array.isArray(editForm?.value)) {
    editForm.value[0].onSubmit();
  }
};

const remove = async (attributeId: number) => {
  const modelId = id.value;
  await store.remove(modelId, attributeId);
  editStore.closeDelete();
};

const getLabel = (item: EntityAttributeDetailDto): string => {
  const prefix = t('entityAttribute.detail.attribute');
  return `${prefix} ${item.order}: ${item.code}`;
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
</style>
