<template>
  <vl-grid v-vl-spacer:bottom.medium>
    <vl-column width="7" width-xs="12">
      <vl-title tag-name="h2"> {{ $t('operationspecification.detail.title') }} </vl-title>
    </vl-column>

    <vl-column width="5" width-xs="12">
      <vl-action-group mod-align-right mod-space between>
        <vl-button
          v-if="items.length === 0"
          icon="plus"
          mod-icon-before
          :mod-disabled="isCreating"
          @click="onCreateButtonClick"
        >
          {{ $t('general.add') }}
        </vl-button>
      </vl-action-group>
    </vl-column>
  </vl-grid>
  <vl-grid :class="{ edit: isCreating }">
    <vl-column>
      <OperationSpecificationCreate v-if="isCreating" />
    </vl-column>
  </vl-grid>
  <vl-grid v-if="items && items.length === 1" :class="{ edit: isEditing }">
    <vl-column width="8">
      <OperationSpecificationEdit v-if="isEditing" ref="editForm" :detail="items[0]" />
      <OperationSpecificationRead v-else :detail="items[0]" />
    </vl-column>
    <vl-column width="4">
      <vl-action-group v-if="!isEditing" mod-align-right mod-space between>
        <vl-button
          icon="pencil"
          mod-icon-before
          mod-secondary
          :mod-disabled="editStore.editing"
          @click="onEditButtonClick"
          >{{ $t('general.edit') }}</vl-button
        >
        <vl-button v-if="!hasChildren" icon="trash" mod-icon-before mod-error @click="onDeleteButtonClick">{{
          $t('general.delete')
        }}</vl-button>
      </vl-action-group>
      <vl-action-group v-else mod-align-right mod-space between>
        <vl-button icon="save" x mod-icon-before @click="onSaveButtonClick">{{ $t('general.save') }}</vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="onCancelSaveButtonClick">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-column>
  </vl-grid>
  <vl-grid v-if="items && items.length" v-vl-spacer:bottom.medium>
    <vl-column width="12">
      <OperationEntity :specification-id="items[0].id" />
    </vl-column>
  </vl-grid>
  <vl-grid v-else v-vl-spacer:bottom.medium>
    <vl-column>
      <vl-annotation>{{ $t('operationspecification.detail.empty') }}</vl-annotation>
    </vl-column>
  </vl-grid>
  <vl-modal
    :id="deleteKey.toString()"
    :title="$t('operationspecification.detail.delete.title')"
    :open="editStore.isDelete(DeliveryProcessDefinitionEditType.OperationSpecification, deleteKey)"
  >
    <vl-action-group>
      <vl-button
        v-if="!hasChildren"
        icon="trash"
        mod-icon-before
        mod-error
        @click="onConfirmRemoveButtonClick(deleteKey)"
      >
        {{ $t('general.delete') }}
      </vl-button>
      <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="onCancelRemoveButtonClick">
        {{ $t('general.cancel') }}
      </vl-button>
    </vl-action-group>
  </vl-modal>
</template>
<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useOperationSpecificationStore } from '@/modules/configuration/store/operation-specification/operation-specification.store';
import { useOperationEntityStore } from '@/modules/configuration/store/operation-entity/operation-entity.store';
import { DeliverySpecificationDetailDto } from '@/api/portal-api/clients';
import {
  DeliveryProcessDefinitionEditType,
  useDeliveryProcessDefinitionEditStore,
} from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-edit.store';
import OperationEntity from '../operation-entity/OperationEntity.vue';
import OperationSpecificationRead from './OperationSpecificationRead.vue';
import OperationSpecificationEdit from './OperationSpecificationEdit.vue';
import OperationSpecificationCreate from './OperationSpecificationCreate.vue';

const store = useOperationSpecificationStore();
const editStore = useDeliveryProcessDefinitionEditStore();
const entityStore = useOperationEntityStore();
const route = useRoute();

const editForm: Ref<typeof OperationSpecificationEdit | (typeof OperationSpecificationEdit)[]> = ref(null);

const items = computed((): DeliverySpecificationDetailDto[] => {
  return store.byDefinition(definitionId.value);
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

const hasChildren = computed((): boolean => {
  const specificationId = items.value[0]?.id;

  const entities = entityStore.bySpecification(specificationId);
  return entities.length > 0;
});

const isCreating = computed((): boolean => {
  return editStore.isCreate(DeliveryProcessDefinitionEditType.OperationSpecification);
});

const isEditing = computed((): boolean => {
  const specificationId = items.value[0]?.id;
  if (specificationId) {
    return editStore.isEdit(DeliveryProcessDefinitionEditType.OperationSpecification, specificationId);
  }
  return false;
});

onMounted(async () => {
  await store.getByDefinition(definitionId.value);
});

const onCreateButtonClick = () => {
  editStore.openCreate(DeliveryProcessDefinitionEditType.OperationSpecification);
};

const onEditButtonClick = () => {
  const specificationId = items.value[0]?.id;
  editStore.openEdit(DeliveryProcessDefinitionEditType.OperationSpecification, specificationId);
};

const onDeleteButtonClick = () => {
  const specificationId = items.value[0]?.id;
  editStore.openDelete(DeliveryProcessDefinitionEditType.OperationSpecification, specificationId);
};

const onSaveButtonClick = () => {
  if (Array.isArray(editForm?.value)) {
    editForm.value[0].onSubmit();
  } else {
    editForm.value.onSubmit();
  }
};

const onCancelSaveButtonClick = () => {
  editStore.closeEdit();
};

const onConfirmRemoveButtonClick = async (deleteKey: number) => {
  await store.remove(definitionId.value, deleteKey);
  editStore.closeDelete();
};

const onCancelRemoveButtonClick = () => {
  editStore.closeDelete();
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
</style>
