<template>
  <vl-layout>
    <PbsGoBackLink :label="$t('general.backToResults')" link="/configuration#deliveryprocessdefinition" />
    <template v-if="!loading">
      <vl-grid>
        <vl-column width="8" width-xs="12">
          <vl-title tag-name="h1"> {{ $t('deliveryprocessdefinition.detail.title') }} </vl-title>
        </vl-column>
        <vl-column width="4" width-xs="12">
          <vl-action-group
            v-if="detail && !editStore.isEdit(DeliveryProcessDefinitionEditType.DeliveryProcessDefinition, detail.id)"
            mod-align-right
            mod-space
            between
          >
            <vl-button
              icon="pencil"
              mod-icon-before
              mod-secondary
              :mod-disabled="editStore.editing"
              @click="editStore.openEdit(DeliveryProcessDefinitionEditType.DeliveryProcessDefinition, detail.id)"
            >
              {{ $t('general.edit') }}
            </vl-button>
            <vl-button
              v-if="!hasChildren"
              icon="trash"
              mod-icon-before
              mod-error
              @click="editStore.openDelete(DeliveryProcessDefinitionEditType.DeliveryProcessDefinition, id)"
              >{{ $t('general.delete') }}</vl-button
            >
          </vl-action-group>
          <vl-action-group v-else mod-align-right mod-space between>
            <vl-button icon="save" :mod-loading="reloading" mod-icon-before @click="save">{{
              $t('general.save')
            }}</vl-button>
            <vl-button
              icon="cross"
              :mod-loading="loading"
              mod-icon-before
              mod-secondary
              mod-error
              @click="editStore.closeEdit"
            >
              {{ $t('general.cancel') }}
            </vl-button>
          </vl-action-group>
        </vl-column>
      </vl-grid>
      <vl-grid
        v-if="detail"
        v-vl-spacer:bottom.medium
        :class="{ edit: editStore.isEdit(DeliveryProcessDefinitionEditType.DeliveryProcessDefinition, detail.id) }"
      >
        <vl-column>
          <DeliveryProcessDefinitionRead
            v-if="!editStore.isEdit(DeliveryProcessDefinitionEditType.DeliveryProcessDefinition, detail.id)"
          />
          <DeliveryProcessDefinitionEdit v-else ref="editForm" />
        </vl-column>
      </vl-grid>
      <template v-if="detail && !loading">
        <DeliverySpecification />
        <div class="vl-u-spacer--large vl-u-visible--s"></div>
        <OperationSpecification />
        <div class="vl-u-spacer--large vl-u-visible--s"></div>
        <DeliveryTransformation />
      </template>
    </template>
    <template v-if="loading">
      <vl-region>
        <div v-vl-align:center>
          <vl-loader :message="$t('deliveryprocessdefinition.detail.loading')" />
        </div>
      </vl-region>
    </template>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('deliveryprocessdefinition.detail.delete.title')"
      :open="editStore.isDelete(DeliveryProcessDefinitionEditType.DeliveryProcessDefinition, deleteKey)"
    >
      <vl-action-group>
        <vl-button v-if="!hasChildren" icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </vl-layout>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave, useRouter } from 'vue-router';
import { useDeliveryProcessDefinitionDetailStore } from '../../store/delivery-process-definition/delivery-process-definition-detail.store';
import { DeliveryProcessDefinitionDetailDto } from '@/api/portal-api/clients';
import DeliverySpecification from './subcomponents/delivery-specification/DeliverySpecification.vue';
import DeliveryProcessDefinitionRead from './DeliveryProcessDefinitionRead.vue';
import OperationSpecification from './subcomponents/operation-specification/OperationSpecification.vue';
import DeliveryTransformation from './subcomponents/delivery-transformation/DeliveryTransformation.vue';
import DeliveryProcessDefinitionEdit from './DeliveryProcessDefinitionEdit.vue';
import { useDeliverySpecificationStore } from '../../store/delivery-specification/delivery-specification.store';
import { useOperationSpecificationStore } from '../../store/operation-specification/operation-specification.store';
import { useDeliveryTransformationStore } from '../../store/delivery-transformation/delivery-transformation.store';
import {
  DeliveryProcessDefinitionEditType,
  useDeliveryProcessDefinitionEditStore,
} from '../../store/delivery-process-definition/delivery-process-definition-edit.store';
import { useAlertStore } from '@/modules/core/store/alert.store';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';

const store = useDeliveryProcessDefinitionDetailStore();
const editStore = useDeliveryProcessDefinitionEditStore();

const route = useRoute();
const router = useRouter();

const editForm = ref();

const detail = computed((): DeliveryProcessDefinitionDetailDto => {
  return store.detail;
});

const loading = computed((): boolean => {
  return store.loading;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const hasChildren = computed((): boolean => {
  return (
    useDeliverySpecificationStore().byDefinition(id.value).length > 0 ||
    useOperationSpecificationStore().byDefinition(id.value).length > 0 ||
    useDeliveryTransformationStore().byDefinition(id.value).length > 0
  );
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

onMounted(() => {
  useAlertStore().clearAlert();
  store.get(id.value);
});

const save = () => {
  editForm.value.onSubmit();
};

const remove = async (id: number) => {
  const isSuccess = await store.delete(id);
  editStore.closeDelete();
  if (isSuccess) {
    router.push({ name: 'configuration', hash: '#deliveryprocessdefinition' });
  }
};

onBeforeRouteLeave((to, from, next) => {
  const id = parseInt(from.params.id as string, 10);
  if (id && store) {
    store.clear(id);
    editStore.closeEdit();
  }
  next();
});
</script>
